export const MARKETING_COPY = {
	common: {
		price: '$39 USD',
		priceFull: '$39.00'
	},
	features: [
		{
			icon: 'tabler:chart-line',
			label: 'Access to TickrrCharts&trade;'
		},
		{
			icon: 'solar:chat-round-money-outline',
			label: 'Access data for over 20,000 symbols'
		},
		// {
		// 	icon: 'tabler:chart-line',
		// 	label: 'Access to advanced charts'
		// },
		{
			icon: 'solar:target-outline',
			label: 'Access to our powerful screener'
		},
		{
			icon: 'solar:documents-outline',
			label: 'Personalized market news'
		},
		{
			icon: 'solar:eye-outline',
			label: 'Customized watchlists'
		},
		{
			icon: 'solar:bell-outline',
			label: 'Up to 20+ price alerts'
		},
		{
			icon: 'solar:rocket-outline',
			label: 'And many more features!'
		}
	],
	subscribeBanner: {
		heading: 'Subscribe to Tickrr Today!',
		text: "Trade smarter with Tickrr, the world's most advanced charting and market research platform."
	},
	subscribeCard: {
		text: "Trade smarter with Tickrr, the world's most advanced charting and market research platform."
	}
};
